import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PrintingServicesPage from "../../components/services/printing-services/index"
const printingservicesPage = () => {
  return (
    <Layout>
      <Seo
        title="Digital Printing Services in Chennai, Bangalore - Mass Media Creatives"
        desc="Mass Media Creatives is committed to provide complete value for money to the clients by offering a wide range of printing solutions that are reasonably priced"
        pathname=""
        keywords="Flex Banner in chennai, Business Card, Printing Services in Chennai, Printing Services in Bangalore"
      />
      <PrintingServicesPage />
    </Layout>
  )
}

export default printingservicesPage
