import React from "react"

const servicesprinting = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <h1 className="page-title exclusive-graphic">OFFSET PRINTING</h1>
          <div className="row">
            <div className="col-12 col-lg-4 pt-4">
              <ul className="service-graphic">
                <li>Business Card</li>
                <li>Letter Head</li>
                <li>Envelope</li>
                <li>Pouch</li>
                <li>Cash Receipt</li>
                <li> DC Book</li>
                <li>Stationery</li>
                <li>Pamphlet</li>
                <li>Brochure</li>
                <li>Catalogue</li>
                <li>Poster</li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block pt-4">
              <ul className="service-graphic">
                <li>Package</li>
                <li>Folder</li>
                <li>Book</li>
                <li>Voucher Booklet</li>
                <li>Stickers / Label</li>
                <li>Invoice / Bill Book</li>
                <li>Dangler</li>
                <li>Wobbler</li>
                <li>POP Material</li>
                <li>Calendar</li>
                <li>T-Shirt</li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block pt-4">
              <ul className="service-graphic">
                <li>Cap</li>
                <li>Cup</li>
                <li>Coffee Table Book</li>
                <li>Menu</li>
                <li>Magazine</li>
                <li>Forms</li>
                <li>Invitation</li>
                <li>Greeting</li>
                <li>Gift Voucher / Coupon</li>
                <li>Annual Planner / report</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 pt-4">
              <p className="story-text">
                We provide personalized digital printing services for indoor and
                outdoor marketing and for short-run purposes. Our digital
                printing services are ideal for startups and other companies to
                establish brand value and to differentiate the brand in an ever
                evolving world. We personalize every copy of your print for
                including unique content. When it comes to digital printing, we
                have a reputation of high creativity, expertise and
                accountability of the work we undertake.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default servicesprinting
