import React from "react"

const printingstyle = () => {
  return (
    <section>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold about-heading">Printing Services</h4>
            <p className="story-text">
              Mass Media Creatives is committed to provide complete value for
              money to the clients by offering a wide range of printing
              solutions that are reasonably priced. We are in a continuous
              process of upgrading our equipment and skills in order to offer
              bespoke printing services with the highest level of precision.
            </p>
            <p className="about-digital pt-4">
              Best offset &amp; Digital Printing solutions under one roof
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default printingstyle
