import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"

const production = () => {
  return (
    <section className="exclusive" id="heros">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading fw-bold digital-production">
              In-house printing production capabilities
            </h4>
            <button type="button" className="btn btn-outline-primary ">
              quality
            </button>
            <button type="button" className="btn btn-outline-success ">
              cost
            </button>
            <button type="button" className="btn btn-outline-warning">
              security
            </button>
            <button type="button" className="btn btn-outline-danger ">
              timing
            </button>
            <button type="button" className="btn btn-outline-secondary ">
              customization
            </button>
            <p className="services-digital text-center pt-4">
              We are a superstore of Printing
            </p>
          </div>
          <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/events-exhibitions">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Events &amp; Exhibitions
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/graphic-design">
                {" "}
                <span>
                Graphic Design{" "}
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
 
        </div>
      </div>
    </section>
  )
}

export default production
