import React from "react"

const digitalprinting = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <h1 className="page-title website-graphic">DIGITAL PRINTING</h1>
          <div className="row">
            <div className="col-12 col-lg-4">
              <ul className="service-graphic pt-4">
                <li>Flex Banner</li>
                <li>Vinyl bOARDS</li>
                <li>Canvas pRINT</li>
                <li> Fabric pRINT</li>
                <li>Back-Lit Boards</li>
                <li>ACP Sign Boards</li>
                <li>LED Sign Boards</li>
                <li>Acrylic Sign Boards</li>
                <li>Metal Letters Sign Boards</li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic  pt-4">
                <li>Neon Sign Boards</li>
                <li>Sun Board / Foam Board</li>
                <li>Display Stands &amp; Shelves</li>
                <li>Dangler &amp; Streamer / Bunting</li>
                <li>Hoarding</li>
                <li>Standee</li>
                <li>Canopy</li>
                <li>Frosted Sticker</li>
                <li>Lamp Post</li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic  pt-4">
                <li>Stall Fabrication</li>
                <li>Kiosk Fabrications </li>
                <li>Bill Board Fabrication</li>
                <li>Unipole Sign Fabrication</li>
                <li>Ambient Sign Fabrication</li>
                <li>Pylon Sign Fabrication</li>
                <li>Arch Printing &amp; Fabrication</li>
                <li>Shop InT. &amp; Ext. Fabrication</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default digitalprinting
