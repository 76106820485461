import React from "react"

const printingcontent = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 ">
            <p className="services-digital text-center">
              Advertising that designs experiences for connecting with people
            </p>
            <p className="story-text">
              Mass Media Creatives presents excellent offset printing services
              that comprises of enormous features to the business brands. Our
              best in class printing setup is equipped for producing prints of a
              higher degree with clarity of information. Offset printing in
              today’s era presents a distinct asset of communication. Offering
              premium offset printing that is the perfect amalgamation of art
              and thoughts brought together in fine prints.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default printingcontent
