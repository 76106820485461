import React from "react"

import gallery from "../../../images/showcase/Image 44.png"

const printinggallery = () => {
  return (
    <>
      <section className="exclusive">
        <div className="helped">
          <img src={gallery} alt="gallery" /> 

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="headprds position-relative">
                  <h4 className="text-start">Printing Services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="graphic-digital">Printing Services</p>
      </section>
    </>
  )
}

export default printinggallery
